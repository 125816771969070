import { Button, FormLabel, Input, NumberInput, NumberInputField, Select, Stat, StatGroup, StatHelpText, StatLabel, StatNumber, Tab, TabList, TabPanel, TabPanels, Tabs, Tag } from '@chakra-ui/react';
import { Form } from 'formik';
import { m } from 'framer-motion';
import { navigate, navigateTo } from 'gatsby';
import { set } from 'lodash';
import React, { useEffect, useState } from 'react';
import AppPageContainer from '../../../components/AppPageContainer';
import ToolBar from '../../../components/ToolBar';
import { CalculateMilage, FastQuote, CalculateFreight, CalculateAir, CalculateParcel } from '../../../modules/calculate_module';
import { createEmission } from '../../../modules/emissions_module';
import { Keys } from '../../../modules/keys_module';
import { confirmQuoute, createQuote } from '../../../modules/offsets_module';

const CalculatePage = () => {
    const keys = Keys.use();

    const [mileage, setMileage] = useState();
    const [weight, setWeight] = useState();
    const [type, setType] = useState("DOMESTIC");
    const [impact, setImpact] = useState(0);
    const [cost, setCost] = useState(0);

    const [emission, setEmission] = useState();
    const [quote, setQuote] = useState();
    
    const [calculator, setCalculator] = useState("mileage")

    const [parcelForm, setParcelForm] = useState({
        origin: {
            street1: "",
            street2: "",
            city: "",
            state: "",
            postalCode: "",
        },
        destination: {
            street1: "",
            street2: "",
            city: "",
            state: "",
            postalCode: "",
        },
        expedience: "GROUND",
        pounds: 0,
        ounces: 0,
    });
    const [parcelCalc, setParcelCalc] = useState();

    const handleMileageChange = (e) => {
        setMileage(e)
    }

    const handleWeightChange = (e) => {
        setWeight(e)
    }
    
    useEffect(() => {
            (async () => {
                if (calculator == "mileage" && mileage > 0 && !weight) {
                    const impact = await CalculateMilage(keys.activeKey, parseInt(mileage));
                    const cost = await FastQuote(keys.activeKey, impact.carbon_impact);
                    setImpact(Math.floor(impact.carbon_impact));
                    setCost(cost.Cost);
                }
                if (calculator === "freight" && mileage > 0 && weight > 0) {
                    const impact = await CalculateFreight(keys.activeKey, parseFloat(weight), parseInt(mileage));
                    const cost = await FastQuote(keys.activeKey, impact.carbon_impact);
                    setImpact((impact.carbon_impact).toFixed(2));
                    setCost(cost.Cost);
                }
                if (calculator === "air" && mileage > 0 && weight > 0) {
                    const impact = await CalculateAir(keys.activeKey, parseFloat(weight), parseInt(mileage), type);
                    const cost = await FastQuote(keys.activeKey, impact.carbon_impact);
                    setImpact((impact.carbon_impact).toFixed(2));
                    setCost(cost.Cost);
                }
                if (calculator === "parcel") {
                    
                }
            })()
    }, [mileage, weight, calculator, type]);

    const calculateParcel = async () => {
        const calc = await CalculateParcel(keys.activeKey, parcelForm.origin, parcelForm.destination, parseFloat(parcelForm.pounds) + (parseFloat(parcelForm.ounces) / 16), parcelForm.expedience)
        setParcelCalc(calc);
    }

    const trackEmission = () => {
        (async () => {
            if (impact > 0) {
                const req = {
                    carbon_impact: impact,
                }
                const emission = await createEmission(keys.activeKey, req);
                setEmission(emission);
                navigate(`/app/emissions/view/${emission.Uid}`)
            }
        })()
    }


    const quoteEmission = () => {
        (async () => {
            const req = {
                carbon_impact: impact,
                track: true,
                classification: "LOGISTICS"
            }
            const data = await createQuote(keys.activeKey, req);
            setQuote(data);
            setMileage(0); setImpact(0); setCost(0);
        })()
    }

    const confirmEmissionQuote = () => {
        (async () => {
            const data = await confirmQuoute(keys.activeKey, quote.Uid);
            setQuote(data);
        })()
    }

    const renderMileage = () => (
        <>
        <div className="p-4">
                <h2 className="text-xl font-bold">Add Truck Route</h2>
                <div className="flex">
                    <div className="flex-grow items-center ">
                        <FormLabel>Mileage</FormLabel>
                        <NumberInput mt={2} value={mileage} onChange={handleMileageChange} placeholder="Mileage">
                            <NumberInputField />
                        </NumberInput>
                    </div>
                    <div className="px-10 font-bold flex items-center">
                        <div>
                            <FormLabel>CO2 Emission</FormLabel>
                            <span>{impact}kg</span>
                        </div>
                    </div>
                    <div className="px-10 font-bold flex items-center">
                        <div>
                            <FormLabel>Cost Estimate</FormLabel>
                            <span>${(cost/100).toFixed(2)}</span>
                        </div>
                    </div>
                </div>
                <div className="mt-2 flex space-x-2">
                    <Button disabled={impact <= 0} onClick={trackEmission}>Track Emission</Button>
                    <Button disabled={impact <= 0} onClick={quoteEmission} >Create Quote</Button>
                </div>
            </div>
            {quote && (
                <div className="mt-2 p-4 border border-gray-200">
                    <div className="text-lg font-bold">Your Carbon Quote</div>
                    <div className="text-sm mb-2 flex items-center space-x-2">
                        <Tag>{quote.Status}</Tag>
                        <div>{quote.Uid}</div>
                    </div>
                    <div>
                        <StatGroup>
                            <Stat>
                                <StatLabel>Carbon Impact</StatLabel>
                                <StatNumber>{quote.carbon_impact}</StatNumber>
                                <StatHelpText>kilograms</StatHelpText>
                            </Stat>
                            <Stat>
                                <StatLabel>Cost</StatLabel>
                                <StatNumber>${(quote.Cost / 100).toFixed(2)}</StatNumber>
                            </Stat>
                        </StatGroup>
                    </div>
                    <div>
                        <Button disabled={quote.Status === "PURCHASED"} onClick={confirmEmissionQuote}>Purchase</Button> 
                    </div>
                </div>
            )}
        </>
    )

    const renderGroundFreight = () => (
        <>
            <div className="p-4 space-y-2">
                <div>
                <FormLabel>Shipment Weight (pounds)</FormLabel>
                <NumberInput value={weight} onChange={setWeight}>
                    <NumberInputField />
                </NumberInput>
                </div>
                <div>
                <FormLabel>Shipment Distance (miles)</FormLabel>
                <NumberInput value={mileage} onChange={setMileage}>
                    <NumberInputField />
                </NumberInput>
                </div>
                <div>
                    {/* <Button disabled={impact <= 0} onClick={trackFreightEmission}>Track Emission</Button> */}
                </div>
                <div className="px-10 font-bold flex items-center">
                        <div>
                            <FormLabel>CO2 Emission</FormLabel>
                            <span>{impact}kg</span>
                        </div>
                    </div>
                    <div className="px-10 font-bold flex items-center">
                        <div>
                            <FormLabel>Cost Estimate</FormLabel>
                            <span>${(cost/100).toFixed(2)}</span>
                        </div>
                    </div>
            </div>
            <div className="text-xs">Our data on freight emissions is from a combination of GHG, Environmental Defense Fund, and Oak Ridge National Laboratory data. We use this data in aggregate to ensure the most accurate estimates.</div>
        </>
    )
    const renderAirFreight = () => (
        <>
            <div className="p-4 space-y-2">
                <div>
                    <FormLabel>Shipment Weight (pounds)</FormLabel>
                    <NumberInput value={weight} onChange={setWeight}>
                        <NumberInputField />
                    </NumberInput>
                </div>
                <div>
                    <FormLabel>Shipment Distance (miles)</FormLabel>
                    <NumberInput value={mileage} onChange={setMileage}>
                        <NumberInputField />
                    </NumberInput>
                </div>
                <div>
                    <FormLabel mt={2}>Shipment Type</FormLabel>
                    <Select onChange={(e) => setType(e.target.value)}>
                        <option value="DOMESTIC">Domestic</option>
                        <option value="INTERNATIONAL">International</option>
                    </Select>
                </div>
                <div>
                    {/* <Button disabled={impact <= 0} onClick={trackFreightEmission}>Track Emission</Button> */}
                </div>
                <div className="px-10 font-bold flex items-center">
                        <div>
                            <FormLabel>CO2 Emission</FormLabel>
                            <span>{impact}kg</span>
                        </div>
                    </div>
                    <div className="px-10 font-bold flex items-center">
                        <div>
                            <FormLabel>Cost Estimate</FormLabel>
                            <span>${(cost/100).toFixed(2)}</span>
                        </div>
                    </div>
            </div>
            <div className="text-xs">Our data on freight emissions is from a combination of GHG, Environmental Defense Fund, and Oak Ridge National Laboratory data. We use this data in aggregate to ensure the most accurate estimates.</div>
        </>
    );

    const renderParcel = () => (
        <>
            <div className="grid grid-cols-2 gap-4">
                <div className="col-span-2">
                    <div>
                        <FormLabel>Expedience</FormLabel>
                        <Select value={parcelForm.expedience} onChange={(e) => setParcelForm({...parcelForm, expedience: e.target.value})}>
                            <option value="GROUND">Standard/Ground</option>
                            <option value="OVERNIGHT">Overnight/Next Day or Air</option>
                        </Select>
                    </div>
                </div>
                <div>
                    <div>
                        <FormLabel>Pounds</FormLabel>
                        <NumberInput value={parcelForm.pounds} onChange={(e) => setParcelForm({...parcelForm, pounds: e})}>
                            <NumberInputField />
                        </NumberInput>
                    </div>
                </div>
                <div>
                    <FormLabel>Ounces</FormLabel>
                    <NumberInput value={parcelForm.ounces} onChange={(e) => setParcelForm({...parcelForm, ounces: e})}>
                        <NumberInputField />
                    </NumberInput>
                </div>
                <div className="space-y-2 border border-gray-200 p-2 rounded">
                    <h1 className="text-lg font-bold">Origin Address</h1>
                    <div>
                        <FormLabel>Street 1</FormLabel>
                        <Input value={parcelForm.origin.street1} onChange={(e) => setParcelForm({...parcelForm, origin: { ...parcelForm.origin, street1: e.target.value}})} />
                    </div>
                    <div>
                        <FormLabel>Street 2</FormLabel>
                        <Input value={parcelForm.origin.street2} onChange={(e) => setParcelForm({...parcelForm, origin: { ...parcelForm.origin, street2: e.target.value}})} />
                    </div>
                    <div>
                        <FormLabel>City</FormLabel>
                        <Input value={parcelForm.origin.city} onChange={(e) => setParcelForm({...parcelForm, origin: { ...parcelForm.origin, city: e.target.value}})} />
                    </div>
                    <div>
                        <FormLabel>State</FormLabel>
                        <Input value={parcelForm.origin.state} onChange={(e) => setParcelForm({...parcelForm, origin: { ...parcelForm.origin, state: e.target.value}})} />
                    </div>
                    <div>
                        <FormLabel>Postal Code</FormLabel>
                        <Input value={parcelForm.origin.postalCode} onChange={(e) => setParcelForm({...parcelForm, origin: { ...parcelForm.origin, postalCode: e.target.value}})}  />
                    </div>
                </div>
                <div className="space-y-2 border border-gray-200 p-2 rounded">
                    <h1 className="text-lg font-bold">Destination Address</h1>
                    <div>
                        <FormLabel>Street 1</FormLabel>
                        <Input value={parcelForm.destination.street1} onChange={(e) => setParcelForm({...parcelForm, destination: { ...parcelForm.destination, street1: e.target.value}})} />
                    </div>
                    <div>
                        <FormLabel>Street 2</FormLabel>
                        <Input value={parcelForm.destination.street2} onChange={(e) => setParcelForm({...parcelForm, destination: { ...parcelForm.destination, street2: e.target.value}})} />
                    </div>
                    <div>
                        <FormLabel>City</FormLabel>
                        <Input value={parcelForm.destination.city} onChange={(e) => setParcelForm({...parcelForm, destination: { ...parcelForm.destination, city: e.target.value}})} />
                    </div>
                    <div>
                        <FormLabel>State</FormLabel>
                        <Input value={parcelForm.destination.state} onChange={(e) => setParcelForm({...parcelForm, destination: { ...parcelForm.destination, state: e.target.value}})} />
                    </div>
                    <div>
                        <FormLabel>Postal Code</FormLabel>
                        <Input value={parcelForm.destination.postalCode} onChange={(e) => setParcelForm({...parcelForm, destination: { ...parcelForm.destination, postalCode: e.target.value}})}  />
                    </div>
                </div>
                <Button onClick={calculateParcel}>Calculate Impact</Button>
            </div>
            { parcelCalc && (
                <div className="mt-4 p-4 border border-gray-200 roumded">
                    <h1 className="mb-2 font-bold text-xl">Carbon Emissioms</h1>
                    <h2 className="text-lg">{parcelCalc.Type}</h2>
                    <h2 className="text-lg mb-2">{parcelCalc.CarbonImpact.toFixed(2)}kg</h2>
                    {parcelCalc.Segments?.map((segment) => (
                        <div className="grid grid-cols-1 border border-gray-200 p-2 rounded mb-2">
                            <StatGroup>
                                <Stat>
                                    <StatLabel>Segment Type</StatLabel>
                                    <StatNumber>{segment.Type}</StatNumber>
                                </Stat>
                                <Stat>
                                    <StatLabel>Segement Distance</StatLabel>
                                    <StatNumber>{segment.Distance.toFixed(2)}</StatNumber>
                                    <StatHelpText>Miles</StatHelpText>
                                </Stat>
                                <Stat>
                                    <StatLabel>Carbon Emissions</StatLabel>
                                    <StatNumber>{segment.CarbonImpact.toFixed(2)}</StatNumber>
                                    <StatHelpText>Kilograms</StatHelpText>
                                </Stat>
                            </StatGroup>
                        </div>
                    ))}
                </div>
            )}
        </>
    );

    const [tabIdx, setTabIdx] = useState(0)
    const handleTabsChange = (idx) => {
        setTabIdx(idx);
        // setMileage()
        // setWeight()
        // setImpact(0)
        // setCost(0)
        const pages = ["mileage","freight","air"]
        setCalculator(pages[idx])
    }

    return (
        <AppPageContainer activePage="calculate">
            <ToolBar pageName="Sustainability Tracker"/>
            <Tabs index={tabIdx} mt={4} onChange={handleTabsChange}>
                <TabList>
                    <Tab>Mileage</Tab>
                    <Tab>Ground Freight</Tab>
                    <Tab>Air Freight</Tab>
                    <Tab>Parcel</Tab>
                </TabList>
                <TabPanels>
                    <TabPanel>
                        {renderMileage()}
                    </TabPanel>
                    <TabPanel>
                        {renderGroundFreight()}
                    </TabPanel>
                    <TabPanel>
                        {renderAirFreight()}
                    </TabPanel>
                    <TabPanel>
                        {renderParcel()}
                    </TabPanel>
                </TabPanels>
            </Tabs>

            {/* {emission && (
                <div className="mt-2 p-4 border border-gray-200">
                    <div className="text-lg font-bold">Your Carbon Emission</div>
                    <div className="text-sm mb-2 flex items-center space-x-2">
                        <Tag>{emission.Status}</Tag>
                        <div>{emission.Uid}</div>
                    </div>                
                </div>
            )} */}
        </AppPageContainer>
    )
}

export default CalculatePage;
