import axios from 'axios';

const api = axios.create({
    baseURL: process.env.GATSBY_API_URL,
    timeout: 2000,
});

export const CalculateParcel = async (apiKey, originAddress, destinationAddress, weight, expedience) => {
    const req = {
        expedience,
        origin: originAddress,
        destination: destinationAddress,
        type: "PARCEL",
        weight,
    }
    const res = await api.post('/offsets/calculations/parcel', req, { withCredentials: true, headers: {"x-api-key": apiKey}})
    return res.data;
}

export const CalculateMilage = async (apiKey, mileage) => {
    const req = {
        type: "diesel",
        distance: mileage,
        fuel_economy: { units: "mpg", quantity: 7 },
    }
    const res = await api.post('/offsets/calculations/vehicle', req, { withCredentials: true, headers: {"x-api-key": apiKey}})
    return res.data;
}

export const CalculateFreight = async (apiKey, weightLbs, mileage) => {
    const req = {
        weight: weightLbs,
        distance: mileage,
    }
    const res = await api.post('/offsets/calculations/freight', req, { withCredentials: true, headers: {"x-api-key": apiKey}})
    return res.data;
}

/**
 * Calculates air freight emissions
 * @param {string} apiKey 
 * @param {number} weightLbs 
 * @param {number} mileage 
 * @param {string} type "domestic" || "international"
 */
export const CalculateAir = async (apiKey, weightLbs, mileage, type) => {
    const req = {
        weight: weightLbs,
        distance: mileage,
        type: type,
    }
    const res = await api.post('/offsets/calculations/air', req, { withCredentials: true, headers: {"x-api-key": apiKey}})
    return res.data;
}

export const FastQuote = async (apiKey, carbon_impact) => {
    const req = {
        carbon_impact
    };
    const res = await api.post("/offsets/fast", req, { withCredentials: true, headers: { "x-api-key": apiKey } })
    return res.data;
}